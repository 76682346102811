import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Section from "../components/Section";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout
      seo={data.strapiHomepage.seo}
      peopleOnlineWidget={data.strapiHomepage.peopleOnlineWidget}
    >
      {data.strapiHomepage.sections.map((section) => (
        <Section
          key={section.id}
          section={section}
          form={data.strapiHomepage.form}
        />
      ))}
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      peopleOnlineWidget
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
          url
        }
      }
      form {
        title
        spreadsheetId
        ctaText
        success
        error
        nameSurname {
          text
          placeholder
          required
          display
        }
        phone {
          text
          placeholder
          required
          display
        }
        email {
          text
          placeholder
          required
          display
        }
        recommendationNameSurname {
          text
          placeholder
          required
          display
        }
        recommendationPhone {
          text
          placeholder
          required
          display
        }
        additionalInfo {
          text
          placeholder
          required
          display
        }
        discountCode {
          text
          placeholder
          required
          display
        }
        agreement {
          text
          required
        }
        thankYouPagePath
      }
      sections
    }
  }
`;

export default IndexPage;
